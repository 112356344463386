import { Suspense, useMemo, lazy } from "react";
import { createRoot } from "react-dom/client";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import GlobalLoading from "components/main/GlobalLoading";
import FullHeight from "components/layout/FullHeight";
import theme from "services/theme";
import "typeface-roboto";

const App = lazy(() =>
  import(/* webpackChunkName: 'App'*/ "components/main/App")
);

function Root() {
  const loadedAt = useMemo(() => new Date(), []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FullHeight>
        <Suspense fallback={<GlobalLoading loadedAt={loadedAt} />}>
          <App loadedAt={loadedAt} />
        </Suspense>
      </FullHeight>
    </ThemeProvider>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Root />);

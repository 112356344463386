import { createTheme, alpha } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { deepmerge } from "@mui/utils";
import Color from "color";
import appConfig from "appConfig";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

function getContrastText(c) {
  return Color(c).luminosity() > 0.4 ? "#000" : "#fff";
}

function getZIndexTokens() {
  return {
    helpDrawer: 1300,
    appBar: 1200,
    drawer: 1100,
    linkHeader: 1000
  };
}

function getPaletteTokens(mode = "light") {
  return {
    palette: {
      mode,
      primary: {
        main: appConfig.themeColorPrimary,
        contrastText: getContrastText(appConfig.themeColorPrimary),
        lighter: Color(appConfig.themeColorPrimary).lighten(0.7).string(),
        background: Color(appConfig.themeColorPrimary).lighten(0.95).string(),
        backgroundLight: Color(appConfig.themeColorPrimary)
          .lighten(0.99)
          .string()
      },
      secondary: {
        main: appConfig.themeColorSecondary,
        contrastText: getContrastText(appConfig.themeColorSecondary)
      },
      error: {
        main: appConfig.themeColorError,
        contrastText: getContrastText(appConfig.themeColorError),
        lighter: Color(appConfig.themeColorError)
          .lighten(0.9)
          .desaturate(0.2)
          .string(),
        background: Color(appConfig.themeColorError).lighten(1.4).string()
      },
      success: {
        main: appConfig.themeColorSuccess,
        contrastText: getContrastText(appConfig.themeColorSuccess),
        background: Color(appConfig.themeColorSuccess).lighten(0.9).string()
      },
      info: {
        main: appConfig.themeColorInfo,
        contrastText: getContrastText(appConfig.themeColorInfo),
        lighter: Color(appConfig.themeColorInfo)
          .lighten(0.6)
          .desaturate(0)
          .string(),
        background: Color(appConfig.themeColorInfo).lighten(0.99).string()
      },
      warning: {
        main: appConfig.themeColorWarning,
        contrastText: getContrastText(appConfig.themeColorWarning),
        lighter: Color(appConfig.themeColorWarning)
          .lighten(0.6)
          .desaturate(0)
          .string(),
        background: Color(appConfig.themeColorWarning).lighten(0.65).string()
      },
      background: {
        paper: mode === "dark" ? "#424242" : "#fff",
        default:
          mode === "dark" ? Color("#424242").lighten(0.16).string() : "#fafafa",
        dark: "#303030"
      },
      text: {
        hint:
          mode === "dark" ? "rgba(255, 255, 255, 0.23)" : "rgba(0,0,0,0.38)",
        secondary:
          mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.54)",
        uri:
          mode === "dark"
            ? Color(appConfig.themeColorError)
                .lighten(0.9)
                .desaturate(0.2)
                .string()
            : Color(appConfig.themeColorPrimary).string()
      },
      default: {
        main: grey[300],
        dark: grey[400]
      }
    }
  };
}

function defaultButtonStyles(theme) {
  return {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", color: "default" },
          style: {
            color: getContrastText(theme.palette.grey[300])
          }
        },
        {
          props: { variant: "outlined", color: "default" },
          style: {
            color: theme.palette.text.primary,
            borderColor:
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.23)"
                : "rgba(255, 255, 255, 0.23)",
            "&.Mui-disabled": {
              border: `1px solid ${theme.palette.action.disabledBackground}`
            },
            "&:hover": {
              borderColor:
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.23)"
                  : "rgba(255, 255, 255, 0.23)",
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity
              )
            }
          }
        },
        {
          props: { color: "default", variant: "text" },
          style: {
            color: theme.palette.text.primary,
            "&:hover": {
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity
              )
            }
          }
        }
      ]
    }
  };
}

const lightPaletteTheme = createTheme(getPaletteTokens());
export default createTheme(
  deepmerge(lightPaletteTheme, {
    zIndex: { ...getZIndexTokens() },
    gutters: {
      rawVertical: appConfig.themeGuttersVertical,
      rawHorizontal: appConfig.themeGuttersHorizontal,
      vertical: lightPaletteTheme.spacing(appConfig.themeGuttersVertical),
      horizontal: lightPaletteTheme.spacing(appConfig.themeGuttersHorizontal)
    },
    components: {
      ...defaultButtonStyles(lightPaletteTheme)
    }
  })
);

const darkPaletteTheme = createTheme(getPaletteTokens("dark"));
export const darkTheme = createTheme(
  deepmerge(darkPaletteTheme, {
    zIndex: { ...getZIndexTokens() },
    gutters: {
      rawVertical: appConfig.themeGuttersVertical,
      rawHorizontal: appConfig.themeGuttersHorizontal,
      vertical: darkPaletteTheme.spacing(appConfig.themeGuttersVertical),
      horizontal: darkPaletteTheme.spacing(appConfig.themeGuttersHorizontal)
    },
    components: {
      ...defaultButtonStyles(darkPaletteTheme),
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "none"
          }
        }
      }
    }
  })
);

export { styled } from "@mui/material/styles";
export { useTheme } from "@mui/material/styles";
export { default as isPropValid } from "@emotion/is-prop-valid";

// TODO: remove when configurable
// https://github.com/emotion-js/emotion/issues/1105
(function disableEmotionSSRWarning() {
  if (process.env.NODE_ENV !== "development") return;
  /* eslint-disable no-console */
  const log = console.error.bind(console);
  console.error = (...args) => {
    if (
      typeof args[0] === "string" &&
      args[0].indexOf("The pseudo class") > -1 &&
      args[0].indexOf(
        "is potentially unsafe when doing server-side rendering. Try changing it to"
      ) > -1
    )
      return;
    log(...args);
  };
  /* eslint-enable no-console */
})();

export function hideProps(...propsArr) {
  return prop => ![propsArr].flat(Infinity).includes(prop);
}

export function simpleGutterStyles({
  theme,
  gutterTop,
  gutterRight,
  gutterLeft,
  gutterBottom
}) {
  let styles = {};
  if (gutterTop) styles = { ...styles, marginTop: theme.spacing(1) };
  if (gutterRight) styles = { ...styles, marginRight: theme.spacing(1) };
  if (gutterLeft) styles = { ...styles, marginLeft: theme.spacing(1) };
  if (gutterBottom) styles = { ...styles, marginBottom: theme.spacing(1) };
  return styles;
}
simpleGutterStyles.props = [
  "gutterTop",
  "gutterLeft",
  "gutterRight",
  "gutterBottom"
];

export function gutterStyles({
  theme,
  gutters,
  gutterTop,
  gutterRight,
  gutterLeft,
  gutterBottom,
  guttersVertical,
  guttersHorizontal
}) {
  let styles = {};
  if (gutters)
    styles = {
      ...styles,
      padding: `${theme.gutters.vertical} ${theme.gutters.horizontal}`
    };
  if (gutterTop) styles = { ...styles, paddingTop: theme.gutters.vertical };
  if (gutterRight)
    styles = { ...styles, paddingRight: theme.gutters.horizontal };
  if (gutterLeft) styles = { ...styles, paddingLeft: theme.gutters.horizontal };
  if (gutterBottom)
    styles = { ...styles, paddingBottom: theme.gutters.vertical };
  if (guttersVertical)
    styles = { ...styles, padding: `${theme.gutters.vertical} 0` };
  if (guttersHorizontal)
    styles = { ...styles, padding: `0 ${theme.gutters.horizontal}` };
  return styles;
}
gutterStyles.props = [
  "gutters",
  "gutterTop",
  "gutterLeft",
  "gutterRight",
  "gutterBottom",
  "guttersVertical",
  "guttersHorizontal"
];

export function marginStyles({ theme, noMargin, denseMargin }) {
  if (noMargin) return { margin: 0 };
  const ft = denseMargin ? 0.5 : 1;
  return {
    margin: `${theme.spacing(theme.gutters.rawVertical * ft)} ${theme.spacing(
      theme.gutters.rawHorizontal * ft
    )}`
  };
}
marginStyles.props = ["noMargin", "denseMargin"];

export function paddingStyles({ theme, noPadding, densePadding }) {
  if (noPadding) return { padding: 0 };
  const ft = densePadding ? 0.5 : 1;
  return {
    padding: `${theme.spacing(theme.gutters.rawVertical * ft)} ${theme.spacing(
      theme.gutters.rawHorizontal * ft
    )}`
  };
}
paddingStyles.props = ["noPadding", "densePadding"];

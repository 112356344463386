let appConfig = {
  loadingDelay: 1000,
  inputDebounce: 700,
  localKeyCacheSize: 100,
  toastTTL: 5000,
  toastPause: 300,
  apiRoot: "/api",
  hubRoot: "/hub",
  imageRoot: "/Images",
  imageAdminRoot: "/api/Sitemap/Images",
  corsRequired: false,
  loginUrl: "/Login",
  logoutUrl: "/Logout",
  changePasswordUrl: "/ChangePassword",
  sessionPendingUrl: "/Pending",
  sessionInactiveUrl: "/Inactive",
  supportedBrowsersUrl: "/supported-browsers",
  iconTableUrl: "https://materialdesignicons.com/",
  defaultGlobalSettings: {
    shortcuts: {
      inlineLocalization: { modifier: "Ctrl", key: "l" },
      quickSubmit: { modifier: "Ctrl", key: "Enter" },
      gridScan: { modifier: "Alt", key: "s" }
    },
    indefiniteDatetime: "2999-01-01T00:00:00Z"
  },
  themeGuttersVertical: 1.5,
  themeGuttersHorizontal: 2,
  themeColorPrimary: "#20a8d8",
  themeColorSecondary: "#162d47",
  themeColorError: "#c00",
  themeColorInfo: "#20a8d8",
  themeColorWarning: "#f9aa33",
  themeColorSuccess: "#32cd32",
  supportedLocales: ["de", "en"],
  defaultLocale: "de"
};

//const DEV_HOST = "";
const DEV_HOST = "https://web.feature-1.dev.peaksecurity.de";
//const DEV_HOST = "https://web.ontras.peaksecurity.de";
//const DEV_HOST = "https://web.nabaltec.peaksecurity.de/";

if (process.env.NODE_ENV === "development") {
  appConfig = {
    ...appConfig,
    corsRequired: true,
    apiRoot: DEV_HOST + "/api",
    hubRoot: DEV_HOST + "/hub",
    imageRoot: DEV_HOST + "/Images",
    imageAdminRoot: DEV_HOST + "/api/Sitemap/Images",
    loginUrl: DEV_HOST + "/Login",
    logoutUrl: DEV_HOST + "/Logout",
    changePasswordUrl: DEV_HOST + "/ChangePassword",
    sessionPendingUrl: DEV_HOST + "/Pending",
    sessionInactiveUrl: DEV_HOST + "/Inactive",
    inactivityTimeoutOverride: 0,
    defaultGlobalSettings: {
      ...appConfig.defaultGlobalSettings,
      shortcuts: {
        ...appConfig.defaultGlobalSettings.shortcuts,
        gridScan: { modifier: "Ctrl", key: "s" }
      }
    }
    // localizationStringsOverride: require("fixtures/LocalizationOverride")
    //   .strings
    //notificationsOverride: require("fixtures/NotificationsOverride")
    //formOverride: require("fixtures/FormOverride")
    //sitemapOverride: require("fixtures/SitemapOverride")
  };
}

export default Object.freeze(appConfig);
